/**
     * 风速 转 风级
     * @param windSpeed
     * @return
     */
 export const windSpeedToWindLevel = (windSpeed) => {
  let index = 0;
  if (0.0 <= windSpeed && windSpeed < 0.3) {
    index = 0;
  } else if (0.3 <= windSpeed && windSpeed < 1.5) {
    index = 1;
  } else if (1.5 <= windSpeed && windSpeed < 3.3) {
    index = 2;
  } else if (3.3 <= windSpeed && windSpeed < 5.4) {
    index = 3;
  } else if (5.4 <= windSpeed && windSpeed < 7.9) {
    index = 4;
  } else if (7.9 <= windSpeed && windSpeed < 10.7) {
    index = 5;
  } else if (10.7 <= windSpeed && windSpeed < 13.8) {
    index = 6;
  } else if (13.8 <= windSpeed && windSpeed < 17.1) {
    index = 7;
  } else if (17.1 <= windSpeed && windSpeed < 20.7) {
    index = 8;
  } else if (20.7 <= windSpeed && windSpeed < 24.4) {
    index = 9;
  } else if (28.4 <= windSpeed && windSpeed < 32.6) {
    index = 10;
  } else if (32.6 <= windSpeed && windSpeed < 36.9) {
    index = 11;
  } else if (36.9 <= windSpeed && windSpeed < 41.4) {
    index = 12;
  } else if (41.4 <= windSpeed && windSpeed < 46.1) {
    index = 13;
  } else if (46.1 <= windSpeed && windSpeed < 50.9) {
    index = 14;
  } else if (50.9 <= windSpeed && windSpeed < 56.0) {
    index = 15;
  } else if (56.0 <= windSpeed && windSpeed < 61.2) {
    index = 16;
  } else {
    index = 17;
  }
  return index + "级";
}


export const windDirectionNameHand = (param) => {
  var level = "";
  if (param > 0 && param < 90) {
    level = "东北";
  } else if (param > 90 && param < 180) {
    level = "东南";
  } else if (param > 180 && param < 270) {
    level = "西南";
  } else if (param > 270 && param < 360) {
    level = "西北";
  } else if (param == 360) {
    level = "正北";
  } else if (param == 270) {
    level = "正西";
  } else if (param == 90) {
    level = "正东";
  } else if (param == 180) {
    level = "正南";
  } else if (param == 0) {
    level = "正北";
  }
  return level + "风";
};