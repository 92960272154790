<template>
  <div class="twoMap">
    <div id="map"></div>
    <div class="infos" v-if="infos.pointName">
      <div class="infos-title">
        {{ $route.query.name }}-{{ infos.monitorTerm }}
      </div>
      <div class="infos-main">
        <template v-if="infos.equipmentName == '客流检测相机'">
          <div>当前设备状态：{{ isWarning }}</div>
          <div>设施瞬时承载量: {{ datas.warningValue }}</div>
          <div>
            {{ infos.pointName.indexOf("入口") >= 0 ? "进入" : "离开" }}总人数:
            {{ datas.personCount }}
          </div>
        </template>

        <template v-if="infos.equipmentName == '人员密度相机'">
          <div>当前设备状态：{{ isWarning }}</div>
          <div>设施瞬时承载量: {{ datas.warningValue }}</div>
          <div>当前设施承载量: {{ datas.newestData }}</div>
        </template>

        <template v-if="infos.equipmentName == '风速风向仪'">
          <div>当前设备状态：{{ isWarning }}</div>
          <div>
            当前风向: {{ windDirectionName(datas.windDirection) }}
            {{ Math.floor(datas.windDirection) }}°
          </div>
          <div>当前风速: {{ windInfo }}</div>
        </template>

        <template v-if="infos.monitorTerm == '间距感应'">
          <div>当前设备状态：{{ isWarning }}</div>
          <div>经过标签: {{ datas.epcs }}</div>
          <div>经过时间: {{ datas.startTime }}</div>
        </template>

        <template v-if="infos.equipmentName == '高空抛物检测相机'">
          <div>当前高空抓拍监测：{{ datas.warningValue }}次</div>
          <div>
            是否有抛物行为:
            {{ datas.newestData === "无抛物行为" ? "无" : "有" }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import mapMixin from "@/mixins/mapMixin.vue";
import request from "@/utils/request";
import { windDirectionNameHand, windSpeedToWindLevel } from "@/utils/index";
let icon1 = require("@/assets/industryMap/monitor-icon-warning.png");
let icon2 = require("@/assets/industryMap/monitor-icon-error.png");
let icon3 = require("@/assets/industryMap/monitor-icon.png");
// 0 没有预警
// 1有预警 没处理
// 2有预警 处理中
// 3没预警 已处理
// 三种图标展示情况
let ICON = {
  0: icon3,
  1: icon1,
  2: icon2,
  3: icon3,
};

export default {
  mixins: [mapMixin],
  data() {
    return {
      infos: {},
      datas: {},
      timer: null,
      currentId: "",
      currentFacilityWaringState: 0,
    };
  },
  methods: {
    // 设置设备文字
    // setLabelShebei(item, facilityPointName) {
    //   item &&
    //     item.length &&
    //     item.map((item, index) => {
    //       if (!item.equipmentPoint) {
    //         return false;
    //       }
    //       let lnglat = (item.equipmentPoint || "").split(",");
    //       let label = new T.Label({
    //         text: item.pointName,
    //         position: new T.LngLat(lnglat[0].trim(), lnglat[1].trim()),
    //         offset: new T.Point(-70, 8),
    //       });
    //       label.setBorderColor("rgba(0,0,0,0)");
    //       label.setBackgroundColor("rgba(0,0,0,0)");
    //       label.setFontColor("#ffffff");
    //       this.setPoly(label);
    //     });
    // },
    // 获取设备数据
    getequipment(subId, id) {
      request
        .post("/securityMap/getScenicSpotCoordinates", { id })
        .then((res) => {
          const data = res.data;
          if (typeof data == "string") {
            this.$msgError(data);
            return;
          }
          const { scenicSpotScope = "" } = data;

          if (scenicSpotScope == null) {
            return;
          }
          let scenicSpotScopedata = this.areaFormatter(scenicSpotScope);

          // let area = scenicSpotScopedata.map((i) => {
          //   return new T.LngLat(i[0], i[1]);
          // });

          // let color = {
          //   color: "transparent",
          //   weight: 0,
          //   opacity: 0.8,
          //   fillColor: "#3366FF",
          //   fillOpacity: 0.5,
          // };
          // let polyArea = new T.Polygon(area, color);
          this.addAreaLayer([scenicSpotScopedata], {
            center: [119.5280672610001, 29.93092132200007],
            zoom: 17,
          });
          request
            .post("/securityMap/getFacilityCoordinates", { id: subId })
            .then((res) => {
              const data = res.data;
              if (typeof data == "string") {
                this.$msgError(data);
                return;
              }
              const {
                facilityPointName = "",
                facilityPoint = "",
                equipmentCoordinates = [],
              } = data;
              // let point =
              //   equipmentCoordinates &&
              //   equipmentCoordinates.length &&
              //   equipmentCoordinates.map((item) => {
              //     if (item.equipmentPoint) {
              //       let equipmentPoint = item.equipmentPoint.split(",");
              //       return new T.LngLat(
              //         equipmentPoint[0].trim(),
              //         equipmentPoint[1].trim()
              //       );
              //     }
              //   });
              // let point1 = facilityPoint.split(",");
              // let lnglat = new T.LngLat(point1[0].trim(), point1[1].trim());
              // this.map.centerAndZoom(lnglat, 17);

              // if (!point) {
              //   return false;
              // }
              this.setMarkerstwoMap(
                equipmentCoordinates,
                // ICON[item.isWarning || 3]
              );
              // this.setShebei(equipmentCoordinates);
              // this.setLabelShebei(equipmentCoordinates, facilityPointName);
            });
        });
    },
    // 设置设备点图标
    setShebei(equipmentCoordinates) {
      // 先判断是否存在数据
      if (equipmentCoordinates && equipmentCoordinates.length) {
        equipmentCoordinates
          .filter((x) => x.equipmentPoint)
          .map((item) => {
            let lnglat = (item.equipmentPoint || "").split(",");
            if (lnglat.length >= 2) {
              let marker = new T.Marker(new T.LngLat(lnglat[0], lnglat[1]), {
                icon: ICON[item.isWarning || 3],
              });
              let that = this;
              marker.addEventListener("click", (e) => {
                // 获取当前点并位移到地图中心
                this.map.panTo(new T.LngLat(e.lnglat.lng, e.lnglat.lat));
                if (this.currentId && this.currentId === item.id) {
                  // 如果当前currentId存在，且等于当前，不再重新触发
                  return false;
                }
                this.currentId = item.id;
                this.currentFacilityWaringState = item.isWarning;
                this.updateInfos();
              });
              this.setPoly(marker);
            }
          });
      }
    },
    getDetail() {
      request
        .post("/industry_security/getEquipmentDataInfo", {
          id: this.currentId,
        })
        .then((res) => {
          this.datas = res.data.equipmentMonitorTermDataVO;
          this.infos = res.data.equipmentVO;
        });
    },
    updateInfos() {
      this.getDetail();
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.getDetail();
      }, 30000);
    },
  },
  mounted() {
    this.initMap();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    "$route.query": {
      handler: function (newVal) {
        if (newVal.id && newVal.subId) {
          if (this.map) {
            this.map.clearOverLays();
          }
          this.getequipment(newVal.subId, newVal.id);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    windDirectionName: () => (param) => windDirectionNameHand(param),
    windLevel: () => (param) => windSpeedToWindLevel(param),
    windInfo: function () {
      if (this.datas.newestData && this.datas.newestData >= 0) {
        return (
          this.windLevel(this.datas.newestData) +
          "(" +
          this.datas.newestData +
          "m/s)"
        );
      } else {
        return "--";
      }
    },
    isWarning: function () {
      return this.currentFacilityWaringState == 1 ||
        this.currentFacilityWaringState == 2
        ? "预警"
        : "正常";
    },
  },
};
</script>
<style lang="less" scope>
.twoMap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // 隐藏天地图
  .tdt-touch .tdt-bar,
  .tdt-touch .tdt-control-copyright,
  .tdt-touch .tdt-control-layers {
    display: none;
  }
  #map {
    width: 100%;
    height: 100%;
    .tdt-label {
      box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
    }
  }
}

.infos {
  position: absolute;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  min-height: 20%;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  padding: 13px;
  &-title {
    font-size: 17px;
    color: #fff;
    line-height: 24px;
    &::after {
      content: "";
      display: block;
      background-color: #fff;
      width: 50px;
      height: 3px;
      border-radius: 2px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &-main {
    font-size: 14px;
    line-height: 26px;
    color: #fff;
  }
}
</style>
